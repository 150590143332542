import React, {useState,useEffect} from "react"
import { Link, graphql } from "gatsby"
import Img from "gatsby-image"
import Layout from "../components/layout"
import WdModal from '../components/modal'
import ModalPost from '../components/modalpost'
import Success from '../components/success'





const IndexPage = ({pageContext,data}) => {

  const [reveal, setReveal] = useState(false)

  const [revealsuccess,setRevealSuccess] = useState(false)

  var metainfo = {
    title: pageContext.metainfo? (pageContext.metainfo.title || 'Σχετικά με εμάς') + " | " + process.env.SITE_NAME  : (pageContext.title || 'Σχετικά με εμάς') + " | " + process.env.SITE_NAME,
    description:pageContext.metainfo? pageContext.metainfo.description || '' : '',
    image:'/images/logo.png'
  }



  return (

    <Layout metainfo={metainfo}>

      <WdModal setRevealSuccess={setRevealSuccess} setReveal={setReveal} reveal={reveal}/>

      <ModalPost reveal={revealsuccess} setReveal={setRevealSuccess}>
        <Success/>
      </ModalPost>







      <div id="home-slider" style={{display:'block'}} className="home-main-block-2 owl-carousel">
        <div className="item home-slider-bg theme-2" style={{backgroundImage: `url("${data.slider.edges[0].node.childImageSharp.fluid.src}")`}}>
          <div className="overlay-bg" />
          <div className="container">
            <div className="home-block text-center">
              {/*<h1 className="home-slider-heading text-white">ΜΕΤΑΦΟΡΕΣ - ΜΕΤΑΚΟΜΙΣΕΙΣ</h1>*/}
              <h1 className="text-white">Η κάθε μετακόμιση είναι ξεχωριστή,</h1>
              <h2 className="text-white">Πάρε άμεσα μια προσφορά!</h2>
              <div className="request-button">
                <button onClick={()=>setReveal(true)} type="button" className="btn btn-request">ΜΑΘΕ ΤΟ ΚΟΣΤΟΣ<i className="las la-arrow-right" /></button>
              </div>
              <div className="home-list text-white mt-5">
                <ul>
                  <li><i className="las la-check-square" />Ασφαλής Μετακόμιση</li>
                  <li><i className="las la-check-square" />Οχι κρυφά κόστη</li>
                  <li><i className="las la-check-square" />Δυνατότητα Πακεταρίσματος</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>


      <div id="about" className="about-main-block theme-2">
        <div className="container">
          <div className="row">
            <div className="col-lg-6 col-md-12">
              <div className="about-left">
                {/* <img className="img-fluid" src="/images/about/about-men-2.png" alt /> */}
                <Img fluid={data.about.edges[0].node.childImageSharp.fluid}
                     alt={`metakomisi`}
                />
              </div>
            </div>
            <div className="col-lg-6 col-md-12">
              <div className="about-content">
                <h1 className="section-heading">Δραστηριοποιούμαστε στο χώρο των μετακομίσεων για πάνω από 25 χρόνια </h1>
                <h5 className="wow slideInUp">Με την πολυετή εμπειρία μας στον χώρο των μετακομίσεων, γνωρίζουμε πως να ολοκληρώσουμε γρήγορα και με επιτυχία ακόμα το πιο απαιτητικό μεταφορικό έργο!</h5>
                <p className="wow slideInDown">Έχοντας επιπλέον σύγχρονο στόλο οχημάτων και εξειδικευμένο προσωπικό, ανταποκρινόμαστε στις ανάγκες κάθε μετακόμισης, αναλαμβάνοντας το πακετάρισμα, την αποθήκευση και μεταφορά των οικοσκευών ή των εμπορευμάτων σας άμεσα και οικονομικά.</p>
                <div className="key-feature">
                  <i className="las la-chevron-circle-right" />
                  <p>Ασφαλής μετακόμιση</p>
                  <i className="las la-chevron-circle-right" />
                  <p>Γρήγορο πακετάρισμα</p>
                  <i className="las la-chevron-circle-right" />
                  <p>Χαμηλές τιμές</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>



      {/* services start */}
      <div id="services" className="services-main-block-2" style={{backgroundImage: 'url("images/bg/best-bg.jpg")'}}>
        <div className="container">
          <div className="section text-center">
            <h1 className="section-heading">Οι Υπηρεσίες μας</h1>
          </div>
          <div className="row">
            <div className="col-lg-4 col-md-6 col-sm-12">
              <div className="single-service-item">
                <div className="download-icon">
                  <img src="/images/icons/service-1.png" className="img-fluid" alt="about-img" />
                </div>
                <h4>Μετακομίσεις</h4>
                <p>Αναλαμβάνουμε μετακομίσεις σε όλη την Ελλάδα.</p>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12">
              <div className="single-service-item">
                <div className="download-icon">
                  <img src="/images/icons/service-2.png" className="img-fluid" alt="about-img" />
                </div>
                <h4>Αποθήκευση</h4>
                <p>Αποθηκεύσετε τον εξοπλισμό σας ή μέρος αυτού για μικρό ή μεγάλο χρονικό διάστημα.</p>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12">
              <div className="single-service-item">
                <div className="download-icon">
                  <img src="/images/icons/service-3.png" className="img-fluid" alt="about-img" />
                </div>
                <h4>Αναβατόριο</h4>
                <p>Διαθέτουμε Ανυψωτικό μηχάνημα για κάθε ανάγκη ανύψωσης.</p>
              </div>
            </div>
            {/* <div className="col-lg-4 col-md-6 col-sm-12">
          <div className="single-service-item">
            <div className="download-icon">
              <img src="/images/icons/service-4.png" className="img-fluid" alt="about-img" />
            </div>
            <h4>Furniture Assets</h4>
            <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit.</p>
          </div>
        </div>
        <div className="col-lg-4 col-md-6 col-sm-12">
          <div className="single-service-item">
            <div className="download-icon">
              <img src="/images/icons/service-5.png" className="img-fluid" alt="about-img" />
            </div>
            <h4>Machinery Items</h4>
            <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit.</p>
          </div>
        </div>
        <div className="col-lg-4 col-md-6 col-sm-12">
          <div className="single-service-item">
            <div className="download-icon">
              <img src="/images/icons/service-6.png" className="img-fluid" alt="about-img" />
            </div>
            <h4>Documents Papers </h4>
            <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit.</p>
          </div>
        </div> */}
          </div>
        </div>
      </div>
      {/* services end*/}
    </Layout>

  )


}

export default IndexPage

export const query = graphql`
  query {

    slider: allFile(filter: {name: {in: ["slider-02"]}}) {
       edges {
         node {
           childImageSharp {
             fluid(jpegProgressive:true,quality:80,maxWidth: 1920) {
               src
             }
           }
         }
       }
     }

     about: allFile(filter: {name: {in: ["about-men-2"]}}) {
        edges {
          node {
            childImageSharp {
              fluid(quality:80,maxWidth: 700) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }


  }
`
